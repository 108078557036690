<template>
  <div class="m-icon" :class="[active ? 'active' : '', reversed ? 'reverse': '']"
    ref="mainIcon" :width="width" :height="height" @click="$emit('click')">
    <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" class="icon">
      <image :xlink:href="detectUri" :width="width" :height="height" />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'MIcons',
  props: {
    width: {
      type: Number,
      default: 35,
    },
    height: {
      type: Number,
      default: 35,
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      green: '#374536',
      white: '#FCE7CC',
    };
  },
  computed: {
    detectUri() {
      return require(`../assets/icons/${this.name}.svg`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.m-icon{
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  &.reverse{
    filter: invert(100%) sepia(22%) saturate(105%) hue-rotate(2deg) brightness(95%) contrast(89%);
  }
  &.active{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background: transparent;
    border-radius: 50%;
  }
}
</style>
